import React from 'react';
import './App.css';
import VerificationForm from './components/VerificationForm'; // Ensure this path is correct

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <VerificationForm />
      </div>
    </div>
  );
}

export default App;
