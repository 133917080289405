import React, { useState } from 'react';
import axios from 'axios';
//import QRCode from 'qrcode.react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const VerificationForm = () => {
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [showLightbox, setShowLightbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state variable for loading indicator
  const [deviceType, setDeviceType] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');

  const checkDeviceType = () => {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    } else if (/CriOS/.test(userAgent) || /Android/i.test(userAgent)) {
      return 'Android';
    } else if (/Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor)) {
      return 'iOS';
    } else {
      return 'Desktop'; // Consider everything else as desktop
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    const device = checkDeviceType();
    setDeviceType(device);

    try {
      let baseUrl;

      if (window.location.hostname === "localhost" || window.location.hostname.startsWith("127.0.0.1")) {
        baseUrl = "http://localhost:3001/verify";
      } else {
        baseUrl = "/verify";
      }
      const formattedDob = dob instanceof Date 
      ? `${("0" + (dob.getMonth() + 1)).slice(-2)}/${("0" + dob.getDate()).slice(-2)}/${dob.getFullYear()}`
      : dob; // If dob is not a date object, send it as is
      const response = await axios.post(baseUrl, { lastName, dob: formattedDob });
      if (response.data.success) {
        const fullUrl = `https://wallet-pass-sandbox.bambumeta.software${response.data.downloadUrl}`;
        setDownloadUrl(fullUrl);
        setShowLightbox(true);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('There was an error!', error);
      alert('An error occurred while verifying.');
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleCloseLightbox = () => {
    setShowLightbox(false);
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <style jsx>{`
          .spinner-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
          }
          .spinner {
            border: 4px solid rgba(0,0,0,.1);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: #09f;
            animation: spin 1s infinite linear;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div>
      <header>
        <img src="https://www.simplepayhealth.com/wp-content/uploads/2020/12/SP-logo-horizontal_702x171.png" alt="SimplePay Health Logo" width="350" />
      </header>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
          <label>Last Name:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Date of Birth:</label>
          <DatePicker
            selected={dob}
            onChange={(date) => setDob(date)}
            dateFormat="MM/dd/yyyy"
            maxDate={new Date()}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            wrapperClassName="form-control"
            className="form-control"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      {showLightbox && (
        <div className="lightbox">
          <div className="lightbox-content">
            {/* Display the proof card image */}
            <img 
              src="https://storage.googleapis.com/staging.bambu-demos.appspot.com/proof_card.png" 
              alt="Proof Card" 
              className="proof-card-image"
            />

            {/* Display the appropriate button based on the device type */}
            {deviceType === 'iOS' && (
              <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
               <img
                className="wallet-button"
                src="/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
                alt="Add to Apple Wallet"
              />
              </a>
            )}
            {deviceType === 'Android' && (
              <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                <img
                  className="wallet-button"
                  src="https://developers.google.com/static/wallet/images/branding/Save_to_phone_white_button_6x.png"
                  alt="Save to Phone"
                />
              </a>
            )}
            {deviceType === 'Desktop' && (
              <button onClick={() => window.location.href = downloadUrl} className="desktop-wallet-button">Add to Wallet</button>
            )}
          </div>
        </div>
      )}

      <style jsx>{`
        .lightbox {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
        }
        .lightbox-content {
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          text-align: center;
          max-width: 90%;
        }
        .lightbox-content img {
          max-width: 80%;
          max-height: 70vh; 
          height: auto; 
        }
        .proof-card-image {
          max-width: 100%;
          height: auto;
          margin-bottom: 20px; // Adds some space between the image and the button
        }
        .verification-form {
          max-width: 500px;
          margin: 0 auto;
          padding: 15px;
          box-sizing: border-box;
        }
        .form-group {
          margin-bottom: 15px;
        }
        .form-group label {
          display: block;
        }
        .form-control {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
        }
        .submit-button {
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 4px;
          background-color: #007bff;
          color: white;
          font-size: 16px;
          cursor: pointer;
          box-sizing: border-box;
        }
        .submit-button:hover {
          background-color: #0056b3;
        }
        
        .wallet-button {
          display: block;
          margin: 10px auto;
          padding: 10px; /* Adjust padding if necessary */
          font-size: 16px; /* Adjust font size if necessary */
        }
      
        @media (max-width: 768px) {
          /* Styles that apply to all elements on screens smaller than 768px */
          .verification-form {
            width: 100%;
            padding: 15px;
          }
          .form-control {
            font-size: 14px;
          }
          .lightbox-content img {
            width: 100%;
          }
          .wallet-button {
            width: 50%; /* Try a smaller percentage or use max-width in pixels */
            max-width: 200px; /* Set a max-width in pixels */
            padding: 8px; /* Smaller padding for mobile */
            font-size: 14px; /* Smaller font size for mobile */
          }
        }
      
        /* Styles for desktop */
        @media (min-width: 768px) {
          .action-button {
            background-color: #007bff; /* Blue background */
            color: white;
            border: none;
            padding: 10px 20px;
            margin-top: 20px;
            border-radius: 5px;
            cursor: pointer;
            text-transform: uppercase; /* Uppercase text for desktop */
          }
          .action-button:hover {
            background-color: #0056b3; /* Darker blue on hover */
          }
        }
      `}</style>
    </div>
  );
};

export default VerificationForm;
